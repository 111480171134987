<template>
  <v-main class="case">
    <div class="app-container pt-10 pb-3">
      <section>
        <div class="d-flex justify-space-between mb-5">
          <Breadcrumbs :items="breadCrumbsItems" />
          <div>
            <v-tooltip
              v-if="
                isLastCaseUnderReview &&
                isDocReview &&
                isAuditNavigationDisabled
              "
              bottom
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :loading="isBatchCloseLoading"
                    :disabled="isDocReview && isAuditNavigationDisabled"
                    color="primary"
                    rounded
                    normal
                    outlined
                    @click="onBatchClose"
                  >
                    Finish Audit
                  </v-btn>
                </div>
              </template>
              <span>Finish case audit to complete audits.</span>
            </v-tooltip>
            <v-tooltip v-else-if="isLastCaseUnderReview" bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :loading="isBatchCloseLoading"
                    color="primary"
                    rounded
                    normal
                    outlined
                    @click="onBatchClose"
                  >
                    Finish Audit
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
          </div>
          <v-btn
            v-if="isExportCaseBtnVisible"
            color="primary"
            normal
            rounded
            outlined
            variant="elevated"
            class="ml-2"
            @click="onExportReport"
          >
            <v-icon left dark>mdi-download</v-icon>
            Export PDF
          </v-btn>
        </div>

        <BatchReviewPanel v-if="isCaseUnderReview" class="mb-5">
          <template v-slot:actions>
            <ReviewProgressBar
              :disabled="isAuditNavigationDisabled"
              @change.once="onNavigationChange"
            />
          </template>
        </BatchReviewPanel>
      </section>

      <section class="case__container d-flex" :class="sectionInfoClass">
        <div v-if="currentCase && caseViewType" class="case__details--sidebar">
          <ResolutionNotes
            v-if="resolutionNoteRolesVisibility.clinicianView"
            :audit-messages="auditMessages"
            :disabled="isCommunicationDisabled"
            class="mb-3"
            @reply="handleReplyNoteSend"
            @edit="handleReplyNoteEdit"
          />

          <v-card v-if="isAuditDocVisible && isCaseUnderReview" class="mb-3">
            <v-card-title> Audit Documentation </v-card-title>

            <v-card-text>
              <FileCard
                :file="auditTemplate.documentationFile"
                variant="download"
              />
            </v-card-text>
          </v-card>

          <AuditCaseHistory
            v-if="historyLogs?.length && !isCaseUnderClinicianReview"
            :logs="historyLogs"
            class="mb-3"
          />

          <div
            class="case__audit-container d-flex flex-column mb-3"
            :class="{ 'flex-column-reverse': isCaseUnderClinicianReview }"
          >
            <div class="case__audit-info">
              <div v-if="isAccountAIFeature && isDocReview">
                <div class="d-flex align-center justify-center mb-5">
                  <v-btn
                    :loading="isAILoading"
                    color="primary"
                    rounded
                    @click="getSuggestion"
                  >
                    AI Audit
                  </v-btn>
                </div>
              </div>

              <AuditEvaluation
                v-if="auditTemplate && auditTemplateType"
                v-model="caseEvaluation"
                :type="auditTemplateType"
                :evaluation-rules="auditTemplate.evaluation"
                :suggestions="suggestions"
                :disabled="isCaseUnderClinicianReview || !batchUnderReview"
                :isClinicalDoc="isDocReview"
                class="mb-3"
                ref="auditEvaluation"
              />

              <StatisticItem
                v-if="isDocReview || isCaseUnderClinicianReview"
                :value="caseScore"
                :max-width="'none'"
                is-percent
                text="Total Case Score"
                class="mb-3"
              />
            </div>
            <div v-if="isNextCaseButtonVisible" class="d-flex align-center">
              <ReviewNextCaseButton
                :next-case-disabled="nextCaseDisabled"
                @change.once="onNavigationChange"
              >
              </ReviewNextCaseButton>
            </div>
            <div v-if="isLastCaseUnderReview && isDocReview" class="mx-5 mb-3">
              <v-tooltip
                :disabled="!isAuditNavigationDisabled"
                bottom
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" class="w-100" style="margin: 0" v-on="on">
                    <v-btn
                      :loading="isBatchCloseLoading"
                      :disabled="isAuditNavigationDisabled"
                      color="primary"
                      rounded
                      normal
                      outlined
                      block
                      @click="onBatchClose"
                    >
                      Finish Audit
                    </v-btn>
                  </div>
                </template>
                <span>Finish case audit to complete audits.</span>
              </v-tooltip>
            </div>
            <v-card
              class="pa-5 py-8"
              :class="{ 'mb-3': isCaseUnderClinicianReview }"
            >
              <div
                v-if="isDocReview"
                class="d-flex justify-space-between align-center mb-8"
              >
                <h4 class="text-body-1 font-weight-bold">Case Status</h4>

                <v-tooltip
                  v-if="isCaseStatusTooltipVisible"
                  bottom
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <CaseStatusChipSelect
                        :disabled="true"
                        :status="caseStatusUnderReview"
                        :items="statusesItems"
                      />
                    </div>
                  </template>
                  <span>Status changes after answering audit questions.</span>
                </v-tooltip>
                <div v-else>
                  <CaseStatusChipSelect
                    :disabled="true"
                    :status="caseStatusUnderReview"
                    :items="statusesItems"
                  />
                </div>
              </div>

              <div class="d-flex justify-space-between align-center">
                <h4 class="text-body-1 font-weight-bold">Last Updated</h4>

                <p class="text-body-2">
                  {{ caseUpdatedAtDate }}
                </p>
              </div>

              <v-btn
                v-if="isAdditionalAuditBtnVisible"
                color="primary"
                rounded
                normal
                outlined
                block
                class="mt-7"
                @click="additionalAuditModal = true"
              >
                Additional audit needed
              </v-btn>
            </v-card>
          </div>
          <div class="d-flex align-center mx-5 pb-2">
            <v-btn
              v-if="messageToClinicianBtnVisible"
              :disabled="isCommunicationClosed"
              color="primary"
              rounded
              normal
              outlined
              block
              @click="handleOpenCommunicationBtn()"
            >
              Message to Clinician
            </v-btn>
          </div>
          <AuditMessages
            v-if="resolutionNoteRolesVisibility.auditorView"
            :audit-messages="auditMessages"
            class="mb-5 mt-5"
          >
            <template
              v-if="!isCommunicationDisabled && isCommunicationActive"
              v-slot:actions
            >
              <v-btn
                color="primary"
                rounded
                normal
                variant="elevated"
                class="ml-6 mr-6 mt-6"
                @click="handleReplyNoteSend"
              >
                Reply
              </v-btn>
            </template>
          </AuditMessages>

          <AuditCommunication
            v-if="communicationEnabled"
            :audit-messages="communications"
            class="mb-3 mt-2"
          >
            <template
              v-if="communicationsRolesVisibility.clinicianView"
              v-slot:actions
            >
              <v-btn
                :disabled="isCommunicationClosed"
                color="primary"
                rounded
                normal
                variant="elevated"
                class="ml-6 mr-6 mt-6"
                @click="handleReplyCommunicationSend"
              >
                Reply
              </v-btn>
            </template>
          </AuditCommunication>

          <div class="d-flex align-center mx-5 pb-3">
            <v-btn
              v-if="isCloseCommunicationBtnVisible"
              color="primary"
              rounded
              normal
              outlined
              block
              @click="handleCloseCommunicationBtn()"
            >
              Close communication
            </v-btn>
          </div>

          <AuditorNotes
            v-if="!isClinician"
            :case-notes="caseNotes"
            class="mb-5"
            @add="handleNoteAdd"
            @edit="handleNoteEdit"
          />
        </div>

        <CaseDetails
          v-if="currentCase"
          :case-data="currentCase"
          :case-view-type="caseViewType"
          :is-review="isDesktopReviewView"
          class="case__details--info mb-5"
        />
      </section>
    </div>

    <TextModal
      :dialog="textModal"
      :title="textModalInfo.title"
      :description="textModalInfo.description"
      :initial-value="textModalInitialData"
      :loading="textModalLoading"
      :toggle-after-submit="false"
      :auto-grow="false"
      :height="200"
      @toggle="handleTextModalToggle"
      @cancel="handleTextModalCancel"
      @send="handleTextModalSend"
    />

    <AddAdditionalAuditModal
      :dialog="additionalAuditModal"
      :audit-template-id="auditTemplate?._id"
      @toggle="handleAdditionalAuditModalToggle"
      @submit="addAdditionalAudit"
    />

    <InvalidCliniciansModal
      v-if="isDocReview"
      :dialog="cliniciansDialog"
      :data="invalidClinicians"
      @close="closeInvalidCliniciansModal"
    />
  </v-main>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useBatchReviewStore } from "@/stores/batchReview";
import { useUserStore } from "@/stores/user";
import { useAccountStore } from "@/stores/account";
import {
  getCaseById,
  addCaseNote,
  updateCaseNote,
  getCaseHistoryLogs,
} from "@/services/cases";
import { createAdditionalAudit } from "@/services/audit";
import { updateAuditMessage } from "@/services/auditMessages";
import { calculateCaseScore } from "@/helpers/audit";
import { parseNavigationQuery } from "@/helpers/parseNavQuery";
import dateToLocalString from "@/helpers/dateToLocalString";
import AuditorNotes from "@/components/case/AuditorNotes.vue";
import CaseStatusChipSelect from "@/components/case/CaseStatusChipSelect.vue";
import CaseDetails from "@/components/case/CaseDetails";
import TextModal from "@/components/common/TextModal.vue";
import BatchReviewPanel from "@/components/batch/BatchReviewPanel.vue";
import ResolutionNotes from "@/components/case/ResolutionNotes.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import AuditEvaluation from "@/components/case/AuditEvaluation.vue";
import FileCard from "@/components/common/FileCard.vue";
import AuditCaseHistory from "@/components/case/AuditCaseHistory.vue";
import ReviewProgressBar from "@/components/batch/ReviewProgressBar.vue";
import AddAdditionalAuditModal from "@/components/case/AddAdditionalAuditModal.vue";
import AuditMessages from "@/components/case/AuditMessages/AuditMessages.vue";
import StatisticItem from "@/components/common/StatisticItem.vue";
import InvalidCliniciansModal from "@/components/batch/InvalidCliniciansModal.vue";
import {
  BatchTypes,
  CaseAuditTypes,
  CasesStatuses,
  CasesStatusesIcons,
  AuditCaseViews,
  AuditOrderTypes,
  AuditMessageTypes,
  AuditStatuses,
  RoutesMapping,
  PagesBreadCrumbTitles,
} from "@/misc/constants";
import {
  changeStatusModalInfo,
  editNotesModalInfo,
  addNoteModalInfo,
  textModalActions,
  AuditCaseStatuses,
  FinalDocReviewsStatuses,
  BreadcrumbItems,
} from "./constants";
import ReviewNextCaseButton from "@/components/batch/ReviewNextCaseButton.vue";
import AuditCommunication from "@/views/auditCommunication/AuditCommunication.vue";
import { getAISuggestions } from "@/services/AIAssistant";
import { getBatchById } from "@/services/batch";

export default {
  name: "CasePage",

  components: {
    AuditCommunication,
    ReviewNextCaseButton,
    CaseDetails,
    AuditorNotes,
    CaseStatusChipSelect,
    TextModal,
    BatchReviewPanel,
    ResolutionNotes,
    Breadcrumbs,
    AuditEvaluation,
    FileCard,
    ReviewProgressBar,
    AddAdditionalAuditModal,
    AuditCaseHistory,
    StatisticItem,
    AuditMessages,
    InvalidCliniciansModal,
  },

  data() {
    return {
      CasesStatusesIcons,
      currentCase: null,
      groupReviewData: null,
      textModal: false,
      textModalAction: null,
      textModalInitialData: "",
      editableNoteData: null,
      caseViewType: null,
      caseStatusUnderReview: null,
      auditCaseView: null,
      AuditCaseViews,
      caseEvaluation: null,
      additionalAuditModal: false,
      messageId: null,
      historyLogs: null,
      isBatchCloseLoading: false,
      textModalLoading: false,
      parsedBreadCrumbs: null,
      auditSectionStyle: null,
      cliniciansDialog: false,
      suggestions: null,
      isAILoading: false,
    };
  },

  computed: {
    ...mapState(useUserStore, ["user", "currentAccount", "isClinician"]),
    ...mapState(useBatchReviewStore, [
      "isBatchReviewActive",
      "reviewBatchType",
      "batchUnderReview",
      "auditTemplateType",
      "auditTemplate",
      "audit",
      "isDocReview",
      "isLastCaseUnderReview",
      "auditMessages",
      "isAuditDocVisible",
      "batch",
      "invalidClinicians",
      "communications",
      "communicationOpenedBy",
      "isCommunicationClosed",
    ]),
    ...mapState(useAccountStore, ["getEncodedLogoImage"]),
    nextCaseDisabled() {
      return this.isAuditNavigationDisabled;
    },
    isNextCaseButtonVisible() {
      return (
        !this.isCaseUnderClinicianReview &&
        this.isCaseUnderReview &&
        !this.isLastCaseUnderReview
      );
    },
    isCaseStatusTooltipVisible() {
      return this.caseStatusUnderReview === CasesStatuses.reviewNeeded;
    },
    breadCrumbsItems() {
      if (this.isCaseUnderClinicianReview) {
        return [
          {
            title: "Home",
            disabled: false,
            to: `/clinicians/${this.user._id}`,
          },
          BreadcrumbItems.caseItem,
        ];
      }
      return this.caseViewType && this.parsedBreadCrumbs
        ? [
            ...(this.parsedBreadCrumbs || []),
            this.isCaseUnderReview
              ? BreadcrumbItems.defaultItem
              : BreadcrumbItems.caseItem,
          ]
        : [
            {
              title: PagesBreadCrumbTitles.cases,
              path: RoutesMapping[PagesBreadCrumbTitles.cases],
            },
            BreadcrumbItems.caseItem,
          ];
    },
    isCaseUnderReview() {
      return !!this.$route.query?.underReview && this.isBatchReviewActive;
    },
    textModalInfo() {
      switch (this.textModalAction) {
        case textModalActions.addNote:
          return addNoteModalInfo;
        case textModalActions.changeStatus:
          return changeStatusModalInfo;
        case textModalActions.editNote:
          return editNotesModalInfo;
        case textModalActions.resolutionSend:
          return {
            title: "Resolution note",
            description: `Why this case is a clinical concern? Send a resolution note to ${this.caseData?.clinician?.name}.`,
          };
        case textModalActions.replyNoteSend:
          return {
            title: "Reply",
            description: "Please reply on a message.",
          };
        case textModalActions.replyNoteEdit:
          return {
            title: "Edit reply note",
            description: "Please change your reply",
          };
        case textModalActions.auditConclusion:
          return {
            title: "Send Final Resolution note",
            description: "Please enter your final resolution",
          };
        case textModalActions.messageToClinicianSend:
          return {
            title: "Send a message to clinician",
            description: "You can start communication flow with clinician",
          };
        case textModalActions.messageToAuditorSend:
          return {
            title: "Send a message to auditor",
            description: "You can chat with auditor",
          };
        default:
          return { title: "", description: "" };
      }
    },
    caseDataByType() {
      if (!this.currentCase || !this.caseViewType || !this.isDocReview) {
        return null;
      }
      return this.currentCase?.[this.caseViewType] || null;
    },
    clinicianToCommunicate() {
      const caseByType =
        this.caseViewType === "all"
          ? this.currentCase?.advice || this.currentCase?.consultation
          : this.currentCase?.[this.caseViewType];
      const { clinicianId: clinician } = caseByType;
      return clinician;
    },
    messageToClinicianBtnVisible() {
      //TODO: Remove when case-based communication logic be done
      if (this.audit?.type === BatchTypes.specialAudit) return false;
      return (
        this.communicationsRolesVisibility.auditorView &&
        (this.$route.query?.underReview || this.$route.query?.auditId)
      );
    },
    caseData() {
      if (!this.caseDataByType) return null;
      const { clinicianId: clinician, conclusionId = {} } = this.caseDataByType;
      return { ...conclusionId, clinician };
    },
    isChangeStatusDisabled() {
      if (!this.isEvaluationFilled || this.isCaseUnderClinicianReview) {
        return true;
      }
      if (!this.isDocReview || !this.caseStatusUnderReview) {
        return false;
      }
      return this.statusesItems.reduce(
        (acc, el) =>
          el.status === this.caseStatusUnderReview && !el.disabled ? true : acc,
        false
      );
    },
    statusesItems() {
      if (!this.isDocReview && this.caseStatusUnderReview) {
        return [];
      }
      return this.caseStatusUnderReview ===
        CasesStatuses.clinicalConcernResponseReceived
        ? FinalDocReviewsStatuses
        : AuditCaseStatuses[BatchTypes.docReview];
    },
    isCaseUnderClinicianReview() {
      if (!this.isClinician || !this.currentCase) {
        return false;
      }
      return !!(
        this.$route.query?.auditId &&
        this.isClinician &&
        this.caseViewType
      );
    },
    sectionInfoClass() {
      return {
        "justify-space-between":
          this.isCaseUnderClinicianReview || this.caseViewType,
        "justify-center":
          !this.caseViewType ||
          (this.isClinician && !this.isCaseUnderClinicianReview),
      };
    },
    caseUpdatedAtDate() {
      if (!this.currentCase) return null;
      return dateToLocalString(this.currentCase.updatedAt);
    },
    isEvaluationFilled() {
      if (!this.caseEvaluation) return false;
      const values = Object.values(this.caseEvaluation);
      if (values.includes(null)) return false;
      return true;
    },
    caseScore() {
      if (!this.caseEvaluation) return 0;
      return calculateCaseScore(this.caseEvaluation);
    },
    isAuditNavigationDisabled() {
      if (!this.isDocReview) return false;
      return this.caseStatusUnderReview === CasesStatuses.reviewNeeded;
    },
    isCommunicationDisabled() {
      if (!this.isDocReview && !this.caseStatusUnderReview) return false;
      return this.caseStatusUnderReview === CasesStatuses.clinicalConcern;
    },
    isCommunicationActive() {
      return (
        this.caseStatusUnderReview ===
        CasesStatuses.clinicalConcernResponseReceived
      );
    },
    clinicianUnderReview() {
      if (!this.caseData) return null;
      return this.caseData?.clinician;
    },
    isExportCaseBtnVisible() {
      return this.auditTemplate && !this.batchUnderReview && this.isDocReview;
    },
    communicationsRolesVisibility() {
      return {
        auditorView: !this.isCaseUnderClinicianReview,
        clinicianView: this.isCaseUnderClinicianReview,
      };
    },
    isCloseCommunicationBtnVisible() {
      //TODO: Remove when case-based communication logic be done
      if (this.audit?.type === BatchTypes.specialAudit) return false;
      return (
        this.communicationsRolesVisibility.auditorView &&
        this.communications &&
        this.communications.length > 0 &&
        this.communicationOpenedBy === this.user._id &&
        !this.isCommunicationClosed
      );
    },
    communicationEnabled() {
      //TODO: Remove when case-based communication logic be done
      if (this.audit?.type === BatchTypes.specialAudit) return false;
      return (
        (this.communicationsRolesVisibility.auditorView ||
          this.communicationsRolesVisibility.clinicianView) &&
        this.communications &&
        this.communications?.length > 0
      );
    },
    resolutionNoteRolesVisibility() {
      if (!this.auditMessages) return false;
      return {
        auditorView: !this.isCaseUnderClinicianReview,
        clinicianView: this.isCaseUnderClinicianReview,
      };
    },
    isAdditionalAuditBtnVisible() {
      if (!this.historyLogs) return false;
      const additionalAdded = !!this.historyLogs.find(
        (el) => el?.type === AuditOrderTypes.additional
      );
      return (
        !this.isCaseUnderClinicianReview &&
        this.batchUnderReview &&
        !additionalAdded
      );
    },
    isDesktopReviewView() {
      return !this.$vuetify.breakpoint.mobile && this.isCaseUnderReview;
    },
    isAccountAIFeature() {
      const { _id } = this.currentAccount;
      return _id === process.env.VUE_APP_ACCOUNT_AI_FEATURE;
    },
  },

  watch: {
    caseEvaluation() {
      if (this.isChangeStatusDisabled) {
        if (!this.caseStatusUnderReview) {
          this.onStatusChange(CasesStatuses.reviewNeeded);
        }
        return;
      }
      if (this.caseScore >= 90) {
        this.onStatusChange(CasesStatuses.compliant);
      } else if (this.caseScore >= 75) {
        this.onStatusChange(CasesStatuses.partiallyCompliant);
      } else {
        this.onStatusChange(CasesStatuses.noncompliant);
      }
    },
  },

  async mounted() {
    this.parsedBreadCrumbs = parseNavigationQuery(this.$route.query);
    await this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (
      this.isCaseUnderReview ||
      this.isCaseUnderClinicianReview ||
      (this.audit && !this.batchUnderReview)
    ) {
      this.reset();
    }
    next();
  },

  methods: {
    dateToLocalString,
    ...mapActions(useBatchReviewStore, [
      "startBatchReview",
      "endBatchReview",
      "reset",
      "initStoredBatchByCaseId",
      "storeBatchData",
      "updateCaseAudit",
      "getAudit",
      "updateAuditWithReply",
      "setAuditMessage",
      "fetchTemplateFromAudit",
      "fetchBatchFromAudit",
      "checkIsAuditUpdateNeeded",
      "updateAuditCommunication",
      "closeAuditCommunication",
      "initAuditCommunications",
    ]),
    async initialize() {
      await this.getCase();
      if (this.$route.query?.auditId && !this.isCaseUnderReview) {
        await this.getCaseAudit(this.$route.query?.auditId);
      } else {
        await this.getCommunications();
      }
      if (
        !this.batchUnderReview &&
        this.$route.query?.auditId &&
        !!this.$route.query?.underReview
      ) {
        const batch = await getBatchById(this.audit?.batchId);
        await this.startBatchReview(batch);
      }
      if (this.$route.query?.caseType) {
        this.setDataMappings();
        this.initializeCaseStatus();
      }
      if (this.audit?.caseEvaluation) {
        this.caseEvaluation = this.audit?.caseEvaluation;
      }
    },
    setDataMappings() {
      const { caseType = null, caseView = null } = this.$route.query;
      this.caseViewType = caseType;
      this.auditCaseView = caseView;
    },
    initializeCaseStatus() {
      if (
        this.isCaseUnderReview &&
        this.reviewBatchType === BatchTypes.specialAudit
      ) {
        return null;
      }
      this.caseStatusUnderReview = this.audit?.caseGrade?.status || null;
    },
    async getCase() {
      try {
        const { id } = this.$route.params;
        this.currentCase = await getCaseById(id, {
          populate: ["consultation.clinicianId", "advice.clinicianId"],
        });
        this.caseNotes = this.currentCase?.notes ? this.currentCase.notes : [];
        await this.getCaseHistory();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch Case Data",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async getCommunications() {
      if (this.audit?._id) {
        await this.initAuditCommunications(this.audit._id);
      }
    },
    buildStatusUpdateMessage(status) {
      return `The status has been changed to "${status}"`;
    },
    handleOpenCommunicationBtn() {
      this.textModalAction = textModalActions.messageToClinicianSend;
      this.textModal = true;
    },
    onStatusChange(status) {
      if (
        status === CasesStatuses.clinicalConcernReplyNeeded ||
        (this.caseStatusUnderReview === CasesStatuses.reviewNeeded &&
          status === CasesStatuses.clinicalConcern)
      ) {
        this.textModalAction = textModalActions.resolutionSend;
        this.textModal = true;
      }
      if (
        this.caseStatusUnderReview ===
        CasesStatuses.clinicalConcernResponseReceived
      ) {
        this.textModalAction = textModalActions.auditConclusion;
        this.textModal = true;
      }
      this.caseStatusUnderReview = status;
    },
    handleTextModalCancel() {
      if (
        this.textModalAction === textModalActions.resolutionSend ||
        this.textModalAction === textModalActions.auditConclusion
      ) {
        this.initializeCaseStatus();
      }
      this.handleTextModalToggle(false);
    },
    handleTextModalToggle(value) {
      if (!value) {
        this.textModalInitialData = "";
      }
      this.textModal = value;
    },
    handleTextModalSend(value) {
      switch (this.textModalAction) {
        case textModalActions.addNote:
          return this.addNote(value);
        case textModalActions.editNote:
          return this.changeNote(value);
        case textModalActions.resolutionSend:
          return this.handleResolutionNoteSend(value);
        case textModalActions.replyNoteEdit:
          return this.updateAuditMessage(value);
        case textModalActions.replyNoteSend:
          return this.handleReplySend(
            value,
            CasesStatuses.clinicalConcernResponseReceived
          );
        case textModalActions.auditConclusion:
          return this.handleReplySend(value, CasesStatuses.clinicalConcern);
        case textModalActions.messageToClinicianSend:
          return this.handleCommunicationSend(value);
        case textModalActions.messageToAuditorSend:
          return this.handleCommunicationSend(value);
      }
    },
    handleNoteEdit(targetNote) {
      this.editableNoteData = targetNote;
      this.textModalInitialData = targetNote?.text || "";
      this.textModalAction = textModalActions.editNote;
      this.textModal = true;
    },
    handleNoteAdd() {
      this.textModalAction = textModalActions.addNote;
      this.textModal = true;
    },
    handleReplyNoteSend() {
      this.textModalAction = textModalActions.replyNoteSend;
      this.textModal = true;
    },
    handleReplyCommunicationSend() {
      this.textModalAction = textModalActions.messageToAuditorSend;
      this.textModal = true;
    },
    handleReplyNoteEdit({ _id, text = "" }) {
      this.messageId = _id;
      this.textModalInitialData = text;
      this.textModalAction = textModalActions.replyNoteEdit;
      this.textModal = true;
    },
    async addNote(text) {
      try {
        this.textModalLoading = true;
        const note = await addCaseNote(this.currentCase._id, { text });
        this.caseNotes.push(note);
        this.$notify({
          type: "success",
          title: "Notes update",
          text: "Note is successfully added",
        });
        this.textModal = false;
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Case notes create",
          text: e?.response.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        this.textModalLoading = false;
      }
    },
    async changeNote(text) {
      try {
        this.textModalLoading = true;
        const caseId = this.currentCase._id;
        const noteId = this.editableNoteData._id;

        const note = await updateCaseNote(caseId, noteId, { text });
        const noteIndex = this.caseNotes.findIndex(
          ({ _id }) => _id === note._id
        );
        this.caseNotes.splice(noteIndex, 1, note);
        this.$notify({
          type: "success",
          title: "Notes update",
          text: "Note is successfully updated",
        });
        this.textModal = false;
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Case notes edit",
          text: e?.response.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        this.textModalLoading = false;
      }
    },
    async onBatchClose() {
      const batchId = this.batchUnderReview._id;
      try {
        this.isBatchCloseLoading = true;
        if (this.isEvaluationFilled) {
          await this.handleAuditUpdate();
        }
        await this.endBatchReview();
        if (this.invalidClinicians?.length) {
          this.cliniciansDialog = true;
          return;
        }
        await this.$router.push(`/batches/${batchId}/statistic`);
      } catch ({ response }) {
        const warningErrorStatuses = ["Unreviewed Batch", "Email Error"];
        if (warningErrorStatuses.includes(response?.data?.error)) {
          this.$notify({
            type: "warning",
            title: response?.data?.error,
            text: response?.data?.message,
          });
          if (response?.data?.error === warningErrorStatuses[1]) {
            this.$router.push(`/batches/${batchId}/statistic`);
          }
        } else {
          this.$notify({
            type: "error",
            text: response?.data?.message,
          });
        }
      } finally {
        this.isBatchCloseLoading = false;
      }
    },
    async onNavigationChange(callback) {
      if (this.isEvaluationFilled) {
        await this.handleAuditUpdate();
      }
      await callback();
    },
    async handleAuditUpdate(auditMessage = null) {
      try {
        if (auditMessage) {
          this.textModalLoading = true;
        }
        const data = {
          caseEvaluation: this.caseEvaluation,
        };
        if (this.isDocReview) {
          data.caseGrade = {
            score: this.caseScore,
            status: this.caseStatusUnderReview,
          };
          if (auditMessage) {
            data.auditMessage = auditMessage;
          }
        }
        if (!this.checkIsAuditUpdateNeeded(data)) return;
        await this.updateCaseAudit(data);
        this.$notify({
          type: "success",
          title: "Audit update",
          text: "Successfully updated",
        });
        this.textModal = false;
      } catch (e) {
        if (this.isDocReview) {
          this.initializeCaseStatus();
        }
        this.$notify({
          type: "error",
          title: "Audit update",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        if (auditMessage) {
          this.textModalLoading = false;
        }
      }
    },
    async addAdditionalAudit(auditTemplateId, callback) {
      try {
        const payload = {
          auditTemplateId,
          caseId: this.currentCase._id,
          caseReference: this.currentCase.caseReference,
        };
        const historyLog = await createAdditionalAudit(payload);
        if (!this.historyLogs?.length) {
          this.historyLogs = [historyLog];
        } else {
          this.historyLogs.push(historyLog);
        }
        this.$notify({
          type: "success",
          title: "Additional review",
          text: "Additional review was added successfully",
        });
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Additional review",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        callback();
      }
    },
    async getCaseAudit(id) {
      try {
        await this.getAudit(id);
        await Promise.all([
          this.fetchTemplateFromAudit(),
          this.fetchBatchFromAudit(),
        ]);
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch audit",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      }
    },
    async updateAuditWithMessage(data) {
      try {
        this.textModalLoading = true;
        await this.updateAuditWithReply(data);
        this.$notify({
          type: "success",
          title: "Audit update",
          text: "Successfully updated",
        });
        this.textModal = false;
      } catch (e) {
        this.initializeCaseStatus();
        this.$notify({
          type: "error",
          title: "Update audit with reply",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        this.textModalLoading = false;
      }
    },
    async updateAuditMessage(text) {
      try {
        if (!this.messageId) return;
        this.textModalLoading = true;
        const updated = await updateAuditMessage(this.messageId, { text });
        this.setAuditMessage(updated);
        this.$notify({
          type: "success",
          title: "Update audit message",
          text: "success",
        });
        this.textModal = false;
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Update audit message",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        this.textModalLoading = false;
      }
    },
    handleAdditionalAuditModalToggle(value) {
      this.additionalAuditModal = value;
    },
    async handleResolutionNoteSend(value) {
      if (!this.clinicianUnderReview) {
        this.$notify({
          type: "warning",
          title: "Update audit",
          text: `Any clinician found by name: ${this.caseData?.clinician?.name}`,
        });
        return;
      }

      const auditMessage = {
        text: value,
        toId: this.clinicianUnderReview._id,
      };

      await this.handleAuditUpdate(auditMessage);
    },
    handleCommunicationSend(text) {
      if (!this.clinicianToCommunicate) {
        this.$notify({
          type: "warning",
          title: "Update audit",
          text: `Any clinician found by name: ${this.caseData?.clinician?.name}`,
        });
        return;
      }

      let toIds = [];

      if (this.clinicianToCommunicate._id === this.user._id) {
        toIds = [
          ...new Set(
            this.communications
              .filter(
                (message) =>
                  message.type === AuditMessageTypes.communicationToClinician
              )
              .map((message) => message?.from)
          ),
        ];
      } else {
        toIds = [this.clinicianToCommunicate._id];
      }

      return this.sendCommunication({
        text,
        toIds,
      });
    },
    async sendCommunication(data) {
      try {
        this.textModalLoading = true;
        await this.updateAuditCommunication(this.audit?._id, data);
        this.$notify({
          type: "success",
          title: "Communication update",
          text: "Successfully updated",
        });
        this.textModal = false;
      } catch (e) {
        this.initializeCaseStatus();
        this.$notify({
          type: "error",
          title: "Update audit communication",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      } finally {
        this.textModalLoading = false;
      }
    },
    async handleCloseCommunicationBtn() {
      try {
        await this.closeAuditCommunication(this.audit?._id);
        this.$notify({
          type: "success",
          title: "Communication update",
          text: "Successfully updated",
        });
      } catch (e) {
        this.initializeCaseStatus();
        this.$notify({
          type: "error",
          title: "Update audit communication",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      }
    },
    handleReplySend(text, status) {
      if (!this.clinicianUnderReview) {
        this.$notify({
          type: "warning",
          title: "Update audit",
          text: `Any clinician found by name: ${this.caseData?.clinician?.name}`,
        });
        return;
      }

      let toId;

      if (this.clinicianUnderReview._id === this.user._id) {
        const resolutionMessage = this.auditMessages.find(
          (message) => message.type === AuditMessageTypes.resolutionNote
        );
        toId = resolutionMessage?.from?._id;
      } else {
        toId = this.clinicianUnderReview._id;
      }

      return this.updateAuditWithMessage({
        text,
        status,
        toId,
      });
    },
    async getCaseHistory() {
      try {
        if (!this.currentCase && !this.caseType) return;
        const logs = await getCaseHistoryLogs(this.currentCase._id);
        this.historyLogs = logs;
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Get Case History Logs",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      }
    },
    async onExportReport() {
      if (this.audit?.status !== AuditStatuses.complete) {
        return this.$notify({
          type: "warning",
          title: "Report Export Warning",
          text: "To export case with audit results, case should be reviewed",
        });
      }

      const { AuditReportFactory } = await import("@/libs/AuditReportFactory");

      const caseType =
        this.caseViewType === CaseAuditTypes.advice ? "Advice" : "Consultation";
      const resolutionMessage = (this.auditMessages || []).find(
        (message) => message?.type === AuditMessageTypes.resolutionNote
      );

      const options = {
        clinicalCase: {
          ...this.currentCase,
          caseType,
          caseData: this.caseData,
        },
        clinician: this.clinicianUnderReview,
        audit: this.audit,
        batch: this.batch,
        template: this.auditTemplate,
        resolutionMessage,
        accountLogo: this.getEncodedLogoImage,
      };

      try {
        const reportFactory = new AuditReportFactory();
        reportFactory.buildClinicalCaseReport(options);
        reportFactory.downloadPdf(`Case-${this.currentCase.caseReference}`);
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Report Export Fail",
          text: error.message,
        });
      }
    },
    async closeInvalidCliniciansModal() {
      this.cliniciansDialog = false;
      if (this.batchUnderReview?._id) {
        await this.$router.push(
          `/batches/${this.batchUnderReview?._id}/statistic`
        );
      }
    },
    async getSuggestion() {
      this.isAILoading = true;
      const { id } = this.$route.params;
      let data = await getAISuggestions(id);
      this.suggestions = Object.values(data).map(
        ({ answer, confidence, description }) => {
          if (!["0", "1", "2", "NA"].includes(answer)) {
            answer = "NA";
            confidence = 0;
          }
          return { [description]: { answer, confidence } };
        }
      );
      this.isAILoading = false;
    },
  },
};
</script>

<style lang="scss">
.case {
  &__container {
    width: 100%;

    @media (max-width: 992px) {
      flex-direction: column !important;
    }
  }

  &__details {
    &--info {
      max-height: 900px;
      overflow-y: auto;
      width: 65%;
    }

    &--sidebar {
      width: 33%;
    }

    @media (max-width: 992px) {
      &--info,
      &--sidebar {
        width: 100%;
      }
    }
  }
}
</style>
